import { deep as baseTheme } from '@theme-ui/presets'

export default {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    'light-grey': '#423e40',
    'dark-grey': '#2d2a2b',
    'soft-black': '#1f1b1d',
    gold: '#b59a63',
    primary: '#b59a63',
    highlight: '#826730',
  },
  fonts: {
    heading: "'Raleway', sans-serif",
    text: "'Raleway', sans-serif",
    'sans-serif': "'Raleway', sans-serif",
  },
  buttons: {
    primary: {
      position: 'relative',
      color: 'white',
      bg: 'gold',
      py: '1rem',
      px: '1.5rem',
      fontSize: 3,
      ':hover': {
        opacity: 0.85,
      },
      ':focus': {
        outline: 'none',
        '::before': {
          content: '""',
          outline: 'none',
          position: 'absolute',
          top: '-3px',
          right: '-3px',
          bottom: '-3px',
          left: '-3px',
          borderRadius: 4,
          boxShadow: '0 0 0 2px',
          color: 'primary',
        },
      },
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
    },
  },
  text: {
    headline: {
      fontSize: [4, null, 8],
      mb: '2',
      fontWeight: 'lighter',
      letterSpacing: ['2px', null, '5px'],
    },
    sectionHeading: {
      fontSize: '6',
      fontWeight: '500',
      letterSpacing: '5px',
      my: '2',
    },
    sectionSubheading: {
      fontSize: '4',
      mb: '2',
    },
    article: {
      as: 'article',
      columnCount: [1, 2],
      columnGap: 4,
      p: {
        mb: 3,
      },
    },
  },
  forms: {
    label: {
      color: 'currentColor',
      fontFamily: 'sans-serif',
      width: 'fit-content',
      my: 2,
      fontSize: 3,
    },
    input: {
      fontFamily: 'sans-serif',
      color: 'black',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      border: 'solid 1px',
      borderColor: 'light-grey',
      borderRadius: '0.25rem',
      '::placeholder': {
        color: 'soft-black',
      },
    },
    textarea: {
      fontFamily: 'sans-serif',
      color: 'black',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      border: 'solid 1px',
      borderColor: 'light-grey',
      borderRadius: '0.25rem',
      '::placeholder': {
        color: 'soft-black',
      },
    },
    radio: {
      mr: 3,
      color: 'gold',
    },
    checkbox: {
      mr: 3,
      color: 'gold',
    },
  },
}
